<template>
	<el-dialog class="dialog" :title="dialogObj.title" :visible.sync="visiable" width="50%">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="120px">
				<el-row v-if="formType!=4">
					<el-col :span="24">
						<el-form-item label="企业">
							<el-input v-model="formData.ent_name" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formType!=4">
					<el-col :span="24">
						<el-form-item label="设备树">
							<el-input v-model="formData.path" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formType==1">
					<el-col :span="24">
						<el-form-item label="名字">
							<el-input v-model="formData.name"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formType==1">
					<el-col :span="24">
						<el-form-item label="排序">
							<el-input v-model="formData.sort"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formType==1">
					<el-col :span="24">
						<el-form-item label="类型">
							<el-select v-model="formData.type_2" clearable placeholder="请选择"
								style="width: 100%;">
								<el-option v-for="item in type2" :key="item.id" :label="item.title"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formType==2">
					<el-col :span="24">
						<el-form-item label="设备类型">
							<el-select v-model="formData.set_unit_type_id" clearable placeholder="请选择"
								style="width: 100%;">
								<el-option v-for="item in unit_type" :key="item.id" :label="item.title"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formType==2">
					<el-col :span="24">
						<el-form-item label="设备名">
							<el-input v-model="formData.name"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formType==3">
					<el-col :span="24">
						<el-form-item label="测点名">
							<el-input v-model="formData.name"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formType==5">
					<el-col :span="24">
						<el-form-item label="整线类型">
							<el-select v-model="formData.set_unit_type_id" clearable placeholder="请选择"
								style="width: 100%;">
								<el-option v-for="item in unit_type" :key="item.id" :label="item.title"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formType==5">
					<el-col :span="24">
						<el-form-item label="整线类型(简化)">
							<el-select v-model="formData.app_unit_type_id" clearable placeholder="请选择"
								style="width: 100%;">
								<el-option v-for="item in unit_type" :key="item.id" :label="item.title"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formType==5">
					<el-col :span="24">
						<el-form-item label="是否整线">
							<el-radio-group v-model="formData.type2">
								<el-radio :label="0">否</el-radio>
								<el-radio :label="10">是</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formType==5">
					<el-col :span="24">
						<el-form-item label="是否使用">
							<el-radio-group v-model="formData.act">
								<el-radio :label="0">否</el-radio>
								<el-radio :label="1">是</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formType!=4&&formType!=5">
					<el-col :span="24">
						<el-form-item label="备注">
							<el-input v-model="formData.remarks"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="dialogObj.type==1000">
					<el-col :span="24">
						<el-form-item label="数据来源">
							<el-radio-group v-model="formData.src">
								<el-radio :label="0">圣名远扬</el-radio>
								<el-radio :label="1">因联</el-radio>
								<el-radio :label="2">容知</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formType==4">
					<el-transfer v-model="clInitValue" :data="clInitData" :props="{
						  key: 'value',
						  label: 'title'
						}" :titles="['未匹配', '已匹配']">
					</el-transfer>
				</el-row>
			</el-form>
			<div class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">提交</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				clInitData: [],
				clInitValue: [],
				formData: {},
				row: this.dialogObj.row,
				ent: {},
				formType: 1,
				unit_type: [],
				loading: false,
				type2:[
					// {
					// 	id:0,
					// 	title:'公司'
					// },
					{
						id:10,
						title:'厂区'
					},
					{
						id:30,
						title:'产线'
					},
					{
						id:50,
						title:'区域'
					},
					{
						id:80,
						title:'台套'
					},
					{
						id:100,
						title:'设备'
					},
					{
						id:1000,
						title:'振动测点'
					},
					{
						id:1001,
						title:'转速测点'
					},
					{
						id:1002,
						title:'温度测点'
					},
					{
						id:1003,
						title:'油液测点'
					},
					{
						id:1004,
						title:'wincc测点'
					}
				]
			};
		},
		props: {},
		watch: {
			isShow(val) {
				if (val) {
					this.open();
					this.init();
				}
			}
		},
		methods: {
			init() {
				this.row = this.dialogObj.row;
				if (this.dialogObj.openType == "insert") {
					this.formType = 1;
					this.formData = {
						path: this.row.path,
						ent_id: this.dialogObj.ent.id,
						ent_name: this.dialogObj.ent.name,
						parent_id: this.row.id,
						level: this.row.level + 1,
						type: this.dialogObj.type
					};
				} else if (this.dialogObj.openType == "edit") {
					this.formType = 1;
					this.loading = true;
					if (this.row.id) {
						this.$get("/backend-api/eqp/set/toUpdate/" + this.row.id, {
							parent_id: this.row.parent_id
						}).then((res) => {
							if (res.code == 1000) {
								this.formData = Object.assign(res.data.tree, {
									ent_name: this.dialogObj.ent.name
								});

								this.loading = false;
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				} else if (this.dialogObj.openType == "equInfo") {
					this.formType = 2;
					this.loading = true;
					if (this.row.id) {
						this.$get("/backend-api/eqp/set/unit/toUpdate/" + this.row.id, null).then((res) => {
							if (res.code == 1000) {
								this.loading = false;
								this.unit_type = res.data.set_unit_types;
								this.formData = Object.assign(res.data.set_unit, {
									path: this.row.path,
									ent_id: this.dialogObj.ent.id,
									ent_name: this.dialogObj.ent.name,
									parent_id: this.row.id,
									level: this.row.level + 1,
									type: this.dialogObj.type
								});
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				} else if (this.dialogObj.openType == "cdInfo") {
					this.formType = 3;
				} else if (this.dialogObj.openType == "clinit") {
					this.formType = 4;
					this.loading = true;
					if (this.row.id) {
						this.$get("/backend-api/eqp/set/unit/meas/toAuth/" + this.row.id, null).then((res) => {
							if (res.code == 1000) {
								this.loading = false;
								console.log(res);
								this.clInitData = res.data.def
								this.clInitValue = res.data.value
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				} else if (this.dialogObj.openType == "glzx") {
					this.formType = 5;
					this.loading = true;
					if (this.row.id) {
						this.$get("/backend-api/eqp/set/toLine/" + this.row.id, null).then((res) => {
							if (res.code == 1000) {
								this.loading = false;
								console.log(res);
								this.unit_type = res.data.set_unit_types;
								this.formData = res.data.line;
								this.formData.ent_name = this.dialogObj.ent.name;
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				} else if (this.dialogObj.openType == "gldt") {
					this.formType = 4;
					this.loading = true;
					if (this.row.id) {
						this.$get("/backend-api/eqp/set/unit/meas/toRpm/" + this.row.id, {
							ent_id: this.dialogObj.ent.id,
							unit_id: this.row.parent_id
						}).then((res) => {
							if (res.code == 1000) {
								this.loading = false;
								this.clInitData = res.data.unit
								this.clInitValue = res.data.value
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				}
			},
			//打开弹窗
			open() {
				this.formData = {};
			},
			submit() {
				this.loading = true;
				if (this.dialogObj.openType == "insert") {
					this.$post("/backend-api/eqp/set/save", this.formData).then((res) => {
						if (res.code == 1000) {
							this.close();
							this.$message.success(res.msg);
							//刷新表格
							this.$parent.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				} else if (this.dialogObj.openType == "edit") {
					this.$put("/backend-api/eqp/set/update", this.formData).then((res) => {
						if (res.code == 1000) {
							this.close();
							this.$message.success(res.msg);
							//刷新表格
							this.$parent.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				} else if (this.dialogObj.openType == "equInfo") {
					this.$put("/backend-api/eqp/set/unit/update", this.formData).then((res) => {
						if (res.code == 1000) {
							this.close();
							this.$message.success(res.msg);
							//刷新表格
							this.$parent.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				} else if (this.dialogObj.openType == "glzx") {
					this.$put("/backend-api/eqp/set/line", this.formData).then((res) => {
						if (res.code == 1000) {
							this.close();
							this.$message.success(res.msg);
							//刷新表格
							this.$parent.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				} else if (this.dialogObj.openType == "clinit") {
					this.formType = 4;
					this.loading = true;
					var args = {
						ent_id: this.dialogObj.ent.id,
						meas_id: this.row.id,
						def_ids: this.clInitValue
					};
					if (this.row.id) {
						this.$postData("/backend-api/eqp/set/unit/meas/auth", args).then((res) => {
							if (res.code == 1000) {
								this.close();
								this.$message.success(res.msg);
								//刷新表格
								this.$parent.list();
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				} else if (this.dialogObj.openType == "gldt") {
					this.formType = 4;
					this.loading = true;
					var args = {
						ent_id: this.dialogObj.ent.id,
						meas_id: this.row.id,
						unit_ids: this.clInitValue
					};
					if (this.row.id) {
						this.$postData("/backend-api/eqp/set/unit/meas/rpm", args).then((res) => {
							if (res.code == 1000) {
								this.close();
								this.$message.success(res.msg);
								//刷新表格
								this.$parent.list();
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				}
			},
			//关闭弹窗
			close() {
				this.loading = false;
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>

</style>
