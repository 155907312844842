<template>
	<div class="equipmentLine">
		<el-table ref="table" :data="tableData" style="width: 100%; margin-bottom: 20px" row-key="id" border
			:tree-props="{ children: 'children'}" @row-click="expandChange">
			<el-table-column prop="name" label="设备树" min-width="30%">
				<template slot-scope="scope">
					<img class="equTree-img" src="@assets/osImg/equipmentLine/qiye.png" v-if="scope.row.level==0"></img>
					<img class="equTree-img" src="@assets/osImg/equipmentLine/zhuzhi.png"
						v-else-if="scope.row.type==10"></img>
					<img class="equTree-img" src="@assets/osImg/equipmentLine/shebei.png"
						v-else-if="scope.row.type==100"></img>
					<img class="equTree-img" src="@assets/osImg/equipmentLine/zhendong.png"
						v-else-if="scope.row.type==1000"></img>
					<img class="equTree-img" src="@assets/osImg/equipmentLine/zhuansu.png"
						v-else-if="scope.row.type==1001"></img>
					<img class="equTree-img" src="@assets/osImg/equipmentLine/wendu.png"
						v-else-if="scope.row.type==1002"></img>
					<img class="equTree-img" src="@assets/osImg/equipmentLine/youye.png"
						v-else-if="scope.row.type==1003"></img>
					<img class="equTree-img" src="@assets/osImg/equipmentLine/jiasudu.png"
						v-else-if="scope.row.type==10000"></img>
					<span class="equTree-span">{{scope.row.name}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="type" label="类型" min-width="7%">
				<template slot-scope="scope">
					<div v-if="scope.row.type==0">
						企业
					</div>
					<div v-if="scope.row.type==10">
						组织
					</div>
					<div v-else-if="scope.row.type==100">
						设备
					</div>
					<div v-else-if="scope.row.type==1000">
						振动
					</div>
					<div v-else-if="scope.row.type==1001">
						转速
					</div>
					<div v-else-if="scope.row.type==1002">
						温度
					</div>
					<div v-else-if="scope.row.type==1003">
						油液
					</div>
					<div v-else-if="scope.row.type==10000">
						测量定义
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="sort" label="排序" min-width="7%" sortable>
			</el-table-column>
			<el-table-column prop="level" label="等级" min-width="7%">
			</el-table-column>
			<el-table-column prop="remarks" label="备注" min-width="10%"> </el-table-column>
			<el-table-column fixed="right" label="操作" min-width="40%" style="padding:0">
				<template slot-scope="scope">
					<div class="operationBtn" v-if="scope.row.type==0">
						<el-button type="text" size="small" @click="insert('添加组织',scope.row,10)">添加组织</el-button>
						<el-button type="text" size="small" @click="insert('添加设备',scope.row,100)">添加设备</el-button>
						<el-button type="text" size="small" @click="edit('编辑设备树',scope.row)">编辑</el-button>
						<el-button type="text" size="small" @click="deleteOne('删除设备树',scope.row)">删除</el-button>
					</div>
					<div class="operationBtn" v-if="scope.row.type==10">
						<el-button type="text" size="small" @click="insert('添加组织',scope.row,10)">添加组织</el-button>
						<el-button type="text" size="small" @click="insert('添加设备',scope.row,100)">添加设备</el-button>
						<el-button type="text" size="small" @click="glzx('关联整线',scope.row)">关联整线</el-button>
						<el-button type="text" size="small" @click="edit('编辑设备树',scope.row)">编辑</el-button>
						<el-button type="text" size="small" @click="deleteOne('删除设备树',scope.row)">删除</el-button>
					</div>
					<div class="operationBtn" v-else-if="scope.row.type==100">
						<el-button type="text" size="small" @click="insert('添加设备',scope.row,100)">添加设备</el-button>
						<el-button type="text" size="small" @click="insert('添加振动测点',scope.row,1000)">添加振动测点
						</el-button>
						<el-button type="text" size="small" @click="insert('添加转速测点',scope.row,1001)">添加转速测点
						</el-button>
						<el-button type="text" size="small" @click="insert('添加温度测点',scope.row,1002)">添加温度测点
						</el-button>
						<el-button type="text" size="small" @click="insert('添加油液测点',scope.row,1003)">添加油液测点
						</el-button>
						<el-button type="text" size="small" @click="edit('编辑',scope.row)">编辑</el-button>
						<el-button type="text" size="small" @click="equInfo('设备信息',scope.row)">设备信息</el-button>
						<el-button type="text" size="small" @click="deleteOne('删除设备树',scope.row)">删除</el-button>
					</div>
					<div class="operationBtn" v-else-if="scope.row.type>=1000&&scope.row.type<10000">
						<el-button type="text" size="small" @click="clinit('测量定义',scope.row,10000)"
							v-if="scope.row.type==1000">测量定义</el-button>
						<el-button type="text" size="small" @click="edit('编辑',scope.row,1000)">编辑</el-button>
						<el-button type="text" size="small" @click="gldt('关联单体',scope.row)" v-if="scope.row.type==1001">
							关联单体</el-button>
						<el-button type="text" size="small" @click="deleteOne('删除设备树',scope.row)">删除</el-button>
					</div>
					<div class="operationBtn" v-else-if="scope.row.type==10000">
						<el-button type="text" size="small" @click="deleteDef('删除设备树',scope.row)">删除</el-button>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
	</div>
</template>


<script>
	import edit from "./edit.vue"
	export default {
		data() {
			return {
				originData: [],
				tableData: [],
				showEditDialog: false,
				editObj: {},
				ents: [],
				currentEnt: {},
			};
		},
		components: {
			edit
		},
		methods: {
			//点击某一行的回调事件
			expandChange(row, index, e) {
				if (index.label == '设备树') {
					this.$refs.table.toggleRowExpansion(row)
				}
			},
			initCurrentEnt(entId) {
				for (var item of this.ents) {
					if (item.id == entId) {
						this.currentEnt = {
							id: item.id,
							name: item.title
						};
						break;
					}
				}
			},
			insert(title, row, type) {
				this.initCurrentEnt(row.ent_id);
				this.editObj = {
					openType: "insert",
					title: title,
					row: row,
					type: type,
					ent: this.currentEnt
				};
				this.showEditDialog = true;
			},
			edit(title, row,type) {
				if (row.level == 0) {
					this.$message.warning("根路径不能编辑");
					return;
				}
				this.initCurrentEnt(row.ent_id);
				this.editObj = {
					openType: "edit",
					title: title,
					row: row,
					ent: this.currentEnt
				};
				if(type){
					this.editObj.type=type;
				}
				this.showEditDialog = true;
			},
			glzx(title, row){
				this.initCurrentEnt(row.ent_id);
				this.editObj = {
					openType: "glzx",
					title: title,
					row: row,
					ent: this.currentEnt
				};
				this.showEditDialog = true;
				
			},
			equInfo(title, row) {
				this.initCurrentEnt(row.ent_id);
				this.editObj = {
					openType: "equInfo",
					title: title,
					row: row,
					ent: this.currentEnt
				};
				this.showEditDialog = true;
			},
			cdInfo(title, row) {
				this.initCurrentEnt(row.ent_id);
				this.editObj = {
					openType: "cdInfo",
					title: title,
					row: row,
					ent: this.currentEnt
				};
				this.showEditDialog = true;
			},
			clinit(title, row) {
				this.initCurrentEnt(row.ent_id);
				this.editObj = {
					openType: "clinit",
					title: title,
					row: row,
					ent: this.currentEnt
				};
				this.showEditDialog = true;
			},
			gldt(title, row) {
				this.initCurrentEnt(row.ent_id);
				this.editObj = {
					openType: "gldt",
					title: title,
					row: row,
					ent: this.currentEnt
				};
				this.showEditDialog = true;
			},
			deleteOne(title, row) {
				if (row.level == 0) {
					this.$message.warning("根路径不能删除");
					return;
				}
				this.initCurrentEnt(row.ent_id);
				var context = "企业：" + this.currentEnt.name + "<br/>";
				context += "设备树：" + row.path + "<br/>"
				context += "是否删除？";
				this.$confirm(context, title, {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$remove("/backend-api/eqp/set/remove/" + row.id, {
						parent_id: row.parent_id,
						ent_id: row.ent_id
					}).then((res) => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				}).catch(() => {});
			},
			deleteDef(title, row) {
				this.initCurrentEnt(row.ent_id);
				this.$confirm("是否删除测量定义", title, {
					dangerouslyUseHTMLString: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$remove("/backend-api/eqp/set/unit/meas/cancelAuth/" + row.id, {
						parent_id: row.parent_id,
						ent_id: row.ent_id
					}).then((res) => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				}).catch(() => {});
			},
			list() {
				this.$get("/backend-api/eqp/set/list", null).then((res) => {
					if (res.code == 1000) {
						// this.originData = res.data.trees;
						// this.convertData();
						this.tableData = res.data.list
						this.ents = res.data.ents;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			// convertData() {
			// 	this.tableData = [];
			// 	//找出父级菜单
			// 	for (var item of this.originData) {
			// 		if (item.parent_id == 0 && item.level == 0) {
			// 			var children = this.getChild(item.id, item.level + 1);
			// 			item.children = children;
			// 			this.tableData.push(item);
			// 		}
			// 	}
			// 	console.log(this.tableData);
			// },
			// getChild(pid, level) {
			// 	var items = [];
			// 	for (var item of this.originData) {
			// 		if (item.parent_id == pid && item.level == level) {
			// 			var children = this.getChild(item.id, item.level + 1);
			// 			item.children = children;
			// 			items.push(item);
			// 		}
			// 	}
			// 	return items;
			// }
		},
		mounted() {
			this.list();
		}
	};
</script>

<style lang='scss' scoped>
	@import "@style/userCenter.scss";

	.equipmentLine {
		background-color: #f5f5f5;
	}

	.operationBtn {
		line-height: 33px;
	}

	.equTree-img {
		height: 16px;
		width: 16px;
		vertical-align: middle;
		margin-right: 3px;
	}

	.equTree-span {
		vertical-align: middle;
	}
</style>
